<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-breadcrumbs class="pa-0 mb-4" :items="[$t('main.back')]">
          <template #item="{ item }">
            <v-breadcrumbs-item
              class="global-cursor-pointer"
              @click="$router.push({ name: routeNames.PROVIDERS })"
            >
              <v-icon>mdi-chevron-left</v-icon>
              {{ item }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-card class="border-secondary" elevation="0" :loading="isLoading">
          <v-card-title class="secondary">
            <span class="text-subtitle-1 mr-2"> {{ $t('providers.new_provider') }} </span>
          </v-card-title>
          <v-card-text class="white py-4">
            <c-toggle-buttons
              v-model="provider.methodType"
              :title="$t('providers.integration_method')"
              :buttons="getTrafficProviderMethodTypes"
              :error-messages="errors.method_type"
            />
            <c-text-field
              v-model="provider.name"
              :label="$t('providers.name')"
              :placeholder="$t('providers.name')"
              hide-details="auto"
              class="my-6"
              label-bold
              :error-messages="errors.name"
              @focus="errors.name = null"
            />
            <div class="my-6">
              <div class="black--text text-truncate text-body-2 font-weight-medium mb-n2">
                {{ $t('providers.ad_formats') }}
              </div>
              <v-chip-group
                :value="provider.adFormatType"
                active-class="primary--text"
                class="pt-0"
                column
                @change="setAdFormatType($event)"
              >
                <v-chip
                  v-for="adFormatType in providerAdFormatTypesByAdNetwork"
                  :key="adFormatType"
                  :value="adFormatType"
                  label
                >
                  {{ $t(`main.ad_format_types.${adFormatType}`) }}
                </v-chip>
              </v-chip-group>
              <errors v-if="errors.ad_format_type" :error-messages="errors.ad_format_type" />
            </div>

            <v-item-group v-model="provider.verticalType" class="my-6">
              <div class="black--text text-truncate text-body-2 font-weight-medium">
                {{ $t('providers.verticals') }}
              </div>
              <v-item
                v-for="verticalType in trafficProviderVerticalTypes"
                :key="verticalType"
                v-slot="{ active, toggle }"
                :value="verticalType"
              >
                <div class="d-flex">
                  <c-switch
                    :input-value="active"
                    class="mb-1"
                    active-class="text-capitalize"
                    @click="toggle()"
                  >
                    <template #label>
                      <span class="text-capitalize">{{ verticalType }}</span>
                    </template>
                  </c-switch>
                </div>
              </v-item>
              <errors v-if="errors.vertical_type" :error-messages="errors.vertical_type" />
            </v-item-group>
            <c-toggle-buttons
              v-model="provider.pricingModel"
              :title="$t('providers.pricing_model')"
              item-text="label"
              class="my-6"
              :buttons="getTrafficProviderPricingModels"
              :error-messages="errors.pricing_model"
            />
            <c-select
              :value="provider.sspFormat.id"
              :items="filteredTrafficProviderSspFormats"
              item-value="id"
              item-text="name"
              :label="$t('providers.list_ssp')"
              :placeholder="$t('providers.custom')"
              class="my-6"
              clearable
              label-bold
              return-object
              hide-details="auto"
              :error-messages="errors.ssp_format_id"
              @change="provider.sspFormat = $event || {}"
            />
            <c-textarea
              v-model="requestExample"
              :error-messages="errors.request_example"
              hide-details="auto"
              :label="$t('providers.request_example')"
              :placeholder="$t('providers.placeholder_custom_mode')"
              class="my-6"
              :readonly="!sspIsCustom"
              label-bold
              rows="15"
              wrap="off"
              @focus="errors.request_example = ''"
            />
            <c-textarea
              v-model="responseExample"
              :error-messages="errors.response_example"
              hide-details="auto"
              :label="$t('providers.response_example')"
              :placeholder="$t('providers.placeholder_custom_mode')"
              :readonly="!sspIsCustom"
              label-bold
              wrap="off"
              rows="15"
              @focus="errors.response_example = ''"
            />
          </v-card-text>

          <v-card-actions class="secondary-lighten px-4 pb-6">
            <v-spacer />
            <c-btn
              color="primary"
              outlined
              depressed
              large
              :loading="isLoading"
              class="py-3 px-4"
              :label="$t('providers.send_the_request')"
              @click="saveProvider()"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import CToggleButtons from '@clickadilla/components/ui/CToggleButtons.vue'
  import CTextField from '@clickadilla/components/ui/CTextField.vue'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import CSwitch from '@clickadilla/components/ui/CSwitch.vue'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import CTextarea from '@clickadilla/components/ui/CTextarea.vue'
  import Errors from '@clickadilla/components/ui/Errors.vue'
  import { mapGetters, mapState } from 'vuex'
  import { providersRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'
  import Provider from '@/services/classes/Provider.js'
  import SspFormat from '@/services/classes/SspFormat.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'CreateProvider',
    components: {
      Errors,
      CToggleButtons,
      CTextField,
      CSwitch,
      CSelect,
      CTextarea,
      CBtn
    },
    data() {
      return {
        routeNames,
        provider: new Provider(),
        errors: {},
        isLoading: false,
        trafficProviderSspFormats: []
      }
    },
    computed: {
      ...mapState('settings', ['trafficProviderVerticalTypes']),
      ...mapGetters('settings', [
        'getTrafficProviderMethodTypes',
        'getAdFormatTypesByAdNetwork',
        'getTrafficProviderPricingModels'
      ]),
      providerAdFormatTypes() {
        return [...new Set(this.trafficProviderSspFormats.map((sspFormat) => sspFormat.adFormatType))]
      },
      providerAdFormatTypesByAdNetwork() {
        return this.providerAdFormatTypes.filter((type) => this.getAdFormatTypesByAdNetwork.includes(type))
      },
      filteredTrafficProviderSspFormats() {
        return this.trafficProviderSspFormats.filter(
          (sspFormat) => sspFormat.adFormatType === this.provider.adFormatType
        )
      },
      sspIsCustom() {
        return !this.provider.sspFormat.id
      },
      requestExample: {
        get() {
          return this.sspIsCustom
            ? this.provider.requestExample
            : JSON.stringify(JSON.parse(this.provider.sspFormat.requestExample), null, 2)
        },
        set(value) {
          this.provider.requestExample = value
        }
      },
      responseExample: {
        get() {
          return this.sspIsCustom
            ? this.provider.responseExample
            : JSON.stringify(JSON.parse(this.provider.sspFormat.responseExample), null, 2)
        },
        set(value) {
          this.provider.responseExample = value
        }
      }
    },
    async created() {
      try {
        const response = await providersRepository.sspFormats()
        this.trafficProviderSspFormats = response.data?.map((format) => new SspFormat(format))
      } catch (error) {
        handleErrors(error)
      }
    },
    methods: {
      async saveProvider() {
        this.isLoading = true
        try {
          await providersRepository.store(this.provider.transformRequest)
          await this.$router.push({ name: routeNames.PROVIDERS })
        } catch (error) {
          this.errors = handleErrors(error)
        }
        this.isLoading = false
      },
      setAdFormatType(adFormatType) {
        this.provider.adFormatType = adFormatType
        if (!this.sspIsCustom) {
          this.provider.sspFormat = {}
        }
      }
    }
  }
</script>
