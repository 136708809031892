export default class SspFormat {
  id = null
  adFormatType = ''
  name = ''
  requestExample = ''
  responseExample = ''

  constructor(data) {
    if (!data) return

    this.id = data.id ?? null
    this.adFormatType = data.ad_format_type ?? ''
    this.name = data.name ?? ''
    this.requestExample = data.request_example ?? ''
    this.responseExample = data.response_example ?? ''
  }
}
